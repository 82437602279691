
$std-bg-color: rgb(255, 255, 255);
$std-color: rgb(0, 0, 0);

@mixin bodyStandard {
    margin: 0;
    background-color: $std-bg-color;
    color: $std-color;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin codeStandard {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
