/*@import './styles/css-reset.css';*/

@use 'common';

body {
  @include common.bodyStandard;
}

code {
  @include common.codeStandard;
}

/* Import parts of the ImageGallery CSS*/
/*@import '~react-image-gallery/styles/css/image-gallery.css';*/
