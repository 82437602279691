.claim {
    text-align: center;
    color: #000000;
    width: 50%;
    margin: auto;
    font-size: 30px;
    margin-top: 2em;
}

.claimQuote {
    margin-top: 1em;
    text-align: right;
}